import request from '../utils/request'

//查询所有菜单树结构
export function getMenuAll() {
    return request({
        url:'/platform/sys/menu/menuAll',
        method:'post'
    })
}
//修改菜单树结构
export function updateSortDo(data) {
    return request({
        url:'/platform/sys/menu/sortDo',
        method:'post',
        data
    })
}
//新增菜单
export function addDo(data) {
    return request({
        url:'/platform/sys/menu/addDo',
        method:'post',
        data
    })
}
//菜单子节点查询
export function getMenuChild(data) {
    return request({
        url:'/platform/sys/menu/child',
        method:'post',
        data
    })
}
//编辑菜单信息
export function editDataDo(data) {
    return request({
        url:'/platform/sys/menu/editDataDo',
        method:'post',
        data
    })
}

//编辑菜单列表
export function editMenuDo(data) {
    return request({
        url:'/platform/sys/menu/editMenuDo',
        method:'post',
        data
    })
}

//编辑菜单
export function editMenu(id) {
    return request({
        url:`/platform/sys/menu/editMenu/${id}`,
        method:'post',
    })
}

//编辑菜单
export function editData(id) {
    return request({
        url:`/platform/sys/menu/editData/${id}`,
        method:'post',
    })
}


//编辑菜单
export function menuType(type,id) {
    return request({
        url:`platform/sys/menu/${type}/${id}`,
        method:'post',
    })
}

//删除菜单信息
export function deleteMenu(id) {
    return request({
        url:`/platform/sys/menu/delete/${id}`,
        method:'post'
    })
}

//获取菜单树数据

export function menuTree(data) {
    return request({
        url:'/platform/sys/menu/tree',
        method:'post',
        data
    })
}
