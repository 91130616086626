<template>
    <el-container class="page-container">
        <el-header class="page-header" style="min-height: 60px;height: auto">
            <el-row class="row-btn-group">
                <el-button size="medium" type="primary" @click="openAdd">
                    <i class="ti-plus"></i>
                    新建菜单
                </el-button>
                <el-button size="medium" @click="openSort">
                    排序
                </el-button>
            </el-row>
        </el-header>
        <el-main class="page-main" style="padding: 0 20px">
            <el-table border :key="tableKey" :data="tableData" style="width: 100%" size="small"
                      :highlight-current-row="true" row-key="id" lazy
                      :load="loadChild">
                <el-table-column label="菜单名称" prop="name"
                                 width="200" :show-overflow-tooltip="true" align="left">

                </el-table-column>
                <el-table-column label="pathName" prop="pathName"
                                 :show-overflow-tooltip="true">
                </el-table-column>

                <el-table-column label="URL" prop="href"
                                 :show-overflow-tooltip="true">
                </el-table-column>

                <el-table-column label="资源类型" prop="type"
                                 :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <i v-if="scope.row.type=='menu'" style="font-weight: bold">菜单</i>
                        <i v-if="scope.row.type=='data'">数据</i>
                    </template>
                </el-table-column>

                <el-table-column label="权限标识" prop="permission"
                                 :show-overflow-tooltip="true">
                </el-table-column>

                <el-table-column label="启用状态" prop="disabled" align="center"
                                 :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <i v-if="scope.row.disabled"
                           class="fa fa-circle text-danger ml5"></i>
                        <i v-if="!scope.row.disabled"
                           class="fa fa-circle text-success ml5"></i>
                    </template>
                </el-table-column>

                <el-table-column label="操作" prop="website"
                                 :show-overflow-tooltip="true" align="center" width="120">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini" type="primary">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="scope.row.type=='menu'"
                                                  :command="{type:'add',id:scope.row.id,name:scope.row.name}">
                                    添加子菜单
                                </el-dropdown-item>
                                <el-dropdown-item divided
                                                  :command="{type:'enable',id:scope.row.id,name:scope.row.name,row:scope.row}">
                                    启用
                                </el-dropdown-item>
                                <el-dropdown-item
                                        :command="{type:'disable',id:scope.row.id,name:scope.row.name,row:scope.row}">
                                    禁用
                                </el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.type=='menu'" divided
                                                  :command="{type:'editMenu',id:scope.row.id,name:scope.row.name}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.type=='data'" divided
                                                  :command="{type:'editData',id:scope.row.id,name:scope.row.name}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'delete',id:scope.row.id,name:scope.row.name}"
                                                  v-if="scope.row.path!='0001'">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>

        <form-dialog @close="handleClose('addForm')" title="新建菜单" :dialogVisible="addDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="addForm" :rules="formRules" size="small" label-width="80px">
                    <el-form-item prop="parentId" label="上级菜单" label-width="80px">
                        <el-cascader v-if="!isAddFromSub" :options="menuOptions" :props="props" v-model="parentMenu"
                                     placeholder="不选择则为顶级" style="width: 100%"></el-cascader>
                        <el-input v-if="isAddFromSub" type="text" v-model="formData.parentName"
                                  :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="菜单名称">
                        <el-input maxlength="100" placeholder="菜单名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="aliasName" label="菜单别名">
                        <el-input maxlength="100" placeholder="菜单名称"
                                  v-model="formData.aliasName"
                                  auto-complete="off" tabindex="4" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="permission" label="权限标识">
                        <el-input maxlength="100" placeholder="权限标识,如 sys.manager.menu"
                                  v-model="formData.permission"
                                  auto-complete="off" tabindex="5" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="pathName" label="pathName">
                        <el-input maxlength="100" placeholder="pathName"
                                  v-model="formData.pathName"
                                  auto-complete="off" tabindex="6" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="href" label="URL">
                        <el-input maxlength="100" placeholder="URL"
                                  v-model="formData.href"
                                  auto-complete="off" tabindex="6" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="icon" label="图标">
                        <el-input maxlength="100" placeholder="图标"
                                  v-model="formData.icon"
                                  auto-complete="off" tabindex="7" type="text"></el-input>
                        <span :class="formData.icon"></span>
                    </el-form-item>
                    <el-form-item prop="disabled" label="启用状态">
                        <el-switch
                                v-model="formData.disabled"
                                active-color="#ff4949"
                                inactive-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                    <el-form-item prop="type" label="数据权限">
                        <el-radio-group @change="formRadioChange" v-model="formData.children" size="mini">
                            <el-radio label="false">无</el-radio>
                            <el-radio label="true">有</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                            v-if="formData.children=='true'"
                            v-for="(menu, index) in formData.buttons"
                            :label="'权限' + (index+1)"
                            :key="menu.key"
                            :prop="'buttons[' + index + ']'"
                            :rules="{required: true,validator: validateMenu,trigger: ['blur','change']}"
                    >
                        <el-row :gutter="2">
                            <el-col :span="6">
                                <el-input v-model="menu.name" placeholder="权限名称"></el-input>
                            </el-col>
                            <el-col :span="13">
                                <el-input v-model="menu.permission"
                                          placeholder="权限标识,如 sys.manager.menu.add"></el-input>
                            </el-col>
                            <el-col :span="4">
                                <el-button @click.prevent="formRemoveMenu(menu)" icon="el-icon-delete"
                                           size="small"></el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-row v-if="formData.children=='true'" style="text-align: right;margin-bottom: 10px">
                        <el-button size="small" @click="formAddMenu" icon="el-icon-circle-plus">添加</el-button>
                    </el-row>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="addDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doAdd">确 定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose('editMenuForm')" title="修改菜单" :dialogVisible="editMenuDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="editMenuForm" :rules="formRules" size="small" label-width="80px">
                    <el-form-item prop="parentId" label="上级菜单" label-width="80px">
                        <el-input type="text" v-model="formData.parentName" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="菜单名称">
                        <el-input maxlength="100" placeholder="菜单名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="aliasName" label="菜单别名">
                        <el-input maxlength="100" placeholder="菜单名称"
                                  v-model="formData.aliasName"
                                  auto-complete="off" tabindex="4" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="permission" label="权限标识">
                        <el-input maxlength="100" placeholder="权限标识,如 sys.manager.menu"
                                  v-model="formData.permission"
                                  auto-complete="off" tabindex="5" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="pathName" label="pathName">
                        <el-input maxlength="100" placeholder="pathName"
                                  v-model="formData.pathName"
                                  auto-complete="off" tabindex="6" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="href" label="URL">
                        <el-input maxlength="100" placeholder="URL"
                                  v-model="formData.href"
                                  auto-complete="off" tabindex="6" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="icon" label="图标">
                        <el-input maxlength="100" placeholder="图标"
                                  v-model="formData.icon"
                                  auto-complete="off" tabindex="7" type="text"></el-input>
                        <span :class="formData.icon"></span>
                    </el-form-item>
                    <el-form-item prop="disabled" label="启用状态">
                        <el-switch
                                v-model="formData.disabled"
                                active-color="#ff4949"
                                inactive-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                    <el-form-item prop="type" label="数据权限">
                        <el-radio-group @change="formRadioChange" v-model="formData.children" size="mini">
                            <el-radio label="false">无</el-radio>
                            <el-radio label="true">有</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                            v-if="formData.children=='true'"
                            v-for="(menu, index) in formData.buttons"
                            :label="'权限' + (index+1)"
                            :key="menu.key"
                            :prop="'buttons[' + index + ']'"
                            :rules="{required: true,validator: validateMenu,trigger: ['blur','change']}"
                    >
                        <el-row :gutter="2">
                            <el-col :span="6">
                                <el-input v-model="menu.name" placeholder="权限名称"></el-input>
                            </el-col>
                            <el-col :span="13">
                                <el-input v-model="menu.permission"
                                          placeholder="权限标识,如 sys.manager.menu.add"></el-input>
                            </el-col>
                            <el-col :span="4">
                                <el-button @click.prevent="formRemoveMenu(menu)" icon="el-icon-delete"
                                           size="small"></el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-row v-if="formData.children=='true'" style="text-align: right;padding-right: 17px;">
                        <el-button size="small" @click="formAddMenu" icon="el-icon-circle-plus">添加</el-button>
                    </el-row>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="editMenuDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doEditMenu">确 定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose('editDataForm')" title="修改权限" :dialogVisible="editDataDialogVisible">
            <template slot="content">
                <el-form :model="formData2" ref="editDataForm" :rules="formRules2" size="small" label-width="80px">
                    <el-form-item prop="name" label="权限名称">
                        <el-input maxlength="100" placeholder="权限名称"
                                  v-model="formData2.name"
                                  auto-complete="off" tabindex="1" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="permission" label="权限标识">
                        <el-input maxlength="100" placeholder="权限标识,如 sys.manager.menu.add"
                                  v-model="formData2.permission"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="disabled" label="启用状态">
                        <el-switch
                                v-model="formData2.disabled"
                                active-color="#ff4949"
                                inactive-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="editDataDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doEditData">确 定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose()" title="菜单排序" :dialogVisible="sortDialogVisible">
            <template slot="content">
                <el-tree
                        ref="sortMenuTree"
                        :data="sortMenuData"
                        draggable
                        :allow-drop="sortAllowDrop"
                        node-key="id"
                        :props="defaultProps"
                >
                    <span class="custom-tree-node" slot-scope="scope">
                        <span>{{ scope.node.label }}</span>
                    </span>
                </el-tree>
                <div class="flex flex-direction-row-reverse btn-group">
                    <el-button @click="sortDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="doSort">确 定</el-button>
                </div>

            </template>
        </form-dialog>
    </el-container>

</template>

<script>
    import formDialog from '../../components/formDialog'
    import {
        getMenuAll,
        updateSortDo,
        addDo,
        getMenuChild,
        editDataDo,
        editMenu,
        editMenuDo,
        editData,
        menuType,
        deleteMenu, menuTree
    } from '../../api/sysMenu'

    export default {
        name: "sysMenu",
        components: {formDialog},
        data() {
            return {
                addDialogVisible: false,
                editMenuDialogVisible: false,
                editDataDialogVisible: false,
                sortDialogVisible: false,
                isAddFromSub: false,
                tableData: [],//后台取出的数据
                tableKey: "",
                formData: {
                    id: "",
                    parentId: "",
                    parentName: "",
                    type: "menu",
                    target: "data-pjax",
                    children: "false",
                    buttons: []
                },
                formRules: {
                    name: [
                        {required: true, message: '菜单名称', trigger: 'blur'}
                    ],
                    permission: [
                        {required: true, message: '权限标识', trigger: ['blur', 'change']}
                    ]
                },
                formData2: {},
                formRules2: {
                    name: [
                        {required: true, message: '权限名称', trigger: 'blur'}
                    ],
                    permission: [
                        {required: true, message: '权限标识', trigger: ['blur', 'change']}
                    ]
                },
                menuOptions: [],
                parentMenu: [],
                sortMenuData: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                props: {
                    lazy: true,
                    checkStrictly: true,
                    multiple: false,
                    async lazyLoad(node, resolve) {
                        try {
                            let formData = {
                                pid: node.value
                            }
                            let {data} = await menuTree(formData)
                            resolve(data);
                        } catch (e) {

                        }
                    }
                }
            }
        },

        methods: {
            handleClose(form) {
                if (form) {
                    this.$refs[form].resetFields();
                }
                this.addDialogVisible = false
                this.editMenuDialogVisible = false
                this.editDataDialogVisible = false
                this.sortDialogVisible = false
            },
            //验证动态添加行数据
            validateMenu: function (rule, value, callback) {
                if (value.name == '') {
                    callback(new Error('权限名称不能为空'));
                } else if (value.permission == '') {
                    callback(new Error('权限标识不能为空'));
                } else {
                    callback();
                }
            },
            formRadioChange: function (val) {
                this.formData.children = val;
                if ("true" == val) {
                    this.formData.buttons = [{
                        name: '',
                        permission: '',
                        key: Date.now()
                    }];
                } else {
                    this.formData.buttons = [];
                }
            },
            formRemoveMenu: function (menu) {
                var index = this.formData.buttons.indexOf(menu);
                if (index !== -1) {
                    this.formData.buttons.splice(index, 1);
                }
            },
            formAddMenu: function () {
                this.formData.buttons.push({
                    name: '',
                    permission: '',
                    key: Date.now()
                });
            },
            //排序树加载
            async sortMenuLoad() {
                try {
                    let {data} = await getMenuAll()
                    this.sortMenuData = data
                } catch (e) {

                }
            },
            //排序树控制不可跨级拖拽
            sortAllowDrop: function (moveNode, inNode, type) {
                if (moveNode.data.parentId == inNode.data.parentId) {
                    return type == 'prev';
                }
            },
            //打开排序功能
            openSort: function () {
                this.sortDialogVisible = true;
                this.sortMenuLoad();
            },
            //递归获取所有的ID值
            getTreeIds (ids, data) {
                data.forEach( (obj)=> {
                    ids.push(obj.id);
                    if (obj.children && obj.children.length > 0) {
                        this.getTreeIds(ids, obj.children);
                    }
                });
            },
            //提交排序后的数据
            async doSort() {
                try {
                    let ids = [];
                    this.getTreeIds(ids, this.sortMenuData);
                    let formData = {
                        ids: ids.toString()
                    }
                    let data = await updateSortDo(formData)
                    this.$message({
                        message: data.msg,
                        type: 'success'
                    });
                    this.sortDialogVisible = false;
                    this.initTreeTable();
                } catch (e) {

                }
            },
            openAdd() {

                this.isAddFromSub = false;
                this.addDialogVisible = true;
                this.parentMenu = [];
                this.menuOptions = [];
                //表单还原为初始值
                this.formData = {
                    id: "",
                    parentId: "",
                    parentName: "",
                    type: "menu",
                    target: "data-pjax",
                    children: "false",
                    buttons: []
                };
                if (this.$refs["addForm"])
                    this.$refs["addForm"].resetFields();
            },
            async doAdd() {
                try {
                    this.$refs["addForm"].validate(async valid => {
                        if (valid) {//表单验证通过
                            if (!this.isAddFromSub) {//如果不是添加子菜单，则从级联选择框取父节点ID
                                this.formData.parentId = this.parentMenu[this.parentMenu.length - 1] || "";
                            }
                            let formData = {
                                menu: JSON.stringify(this.formData),
                                buttons: JSON.stringify(this.formData.buttons)
                            }
                            let data = await addDo(formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.addDialogVisible = false;
                            this.initTreeTable();
                        }
                    })
                } catch (e) {

                }
            },
            async doEditMenu() {
                this.$refs["editMenuForm"].validate(async (valid) => {
                    try {
                        let formData = {
                            menu: JSON.stringify(this.formData),
                            buttons: JSON.stringify(this.formData.buttons)
                        }
                        let data = await editMenuDo(formData)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.editMenuDialogVisible = false;
                        this.initTreeTable();
                    } catch (e) {

                    }
                })
            },
            async doEditData() {
                try {
                    this.$refs["editDataForm"].validate(async (valid) => {
                        let formData = this.formData2
                        let data = await editDataDo(formData)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.editDataDialogVisible = false;
                        this.initTreeTable();
                    })
                } catch (e) {

                }
            },
            async initTreeTable() {
                try {
                    let formData = {
                        pid: ""
                    }
                    let {data} = await getMenuChild(formData)
                    this.tableData = data;
                    this.tableKey = +new Date();
                } catch (e) {

                }
            },
            async loadChild(tree, treeNode, resolve) {
                try {
                    let formData = {
                        pid: tree.id
                    }
                    let {data} = await getMenuChild(formData)
                    resolve(data);
                } catch (e) {

                }
            },
            async dropdownCommand(command) {//监听下拉框事件
                if ("add" == command.type) {
                    this.openAdd();
                    this.formData.parentId = command.id;
                    this.formData.parentName = command.name;
                    this.isAddFromSub = true;
                }
                if ("editMenu" == command.type) {
                    try {
                        let {data} = await editMenu(command.id)
                        this.formData = data;//加载后台表单数据
                        this.editMenuDialogVisible = true;//打开编辑窗口
                    } catch (e) {

                    }
                }
                if ("editData" == command.type) {
                    try {
                        let {data} = await editData(command.id)
                        this.formData2 = data;//加载后台表单数据
                        this.editDataDialogVisible = true;//打开编辑窗口
                    } catch (e) {

                    }
                }
                if ("enable" == command.type || "disable" == command.type) {
                    try {
                        let data = await menuType(command.type, command.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        if ("disable" == command.type) {
                            command.row.disabled = true;
                        }
                        if ("enable" == command.type) {
                            command.row.disabled = false;
                        }
                    } catch (e) {

                    }
                }
                if ("delete" == command.type) {
                    try {
                        await this.$confirm('此操作将删除 ' + command.name, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        let data = await deleteMenu(command.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.initTreeTable();
                    } catch (e) {
                    console.log(e)
                    }

                }
            }

        },
        created() {
            this.initTreeTable();
        }
    }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }

</style>
